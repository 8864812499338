html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
}

body {
  font-family: "Space Mono", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color: white;
  font-weight: 400;
  text-rendering: optimizeSpeed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #f55f44;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.image--content {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.animate___forwards {
  animation-fill-mode: forwards !important;
}

:root {
  --animate-delay: 0.1s;
}

.not-visible {
  visibility: hidden !important;
}

.visible {
  visibility: visible !important;
}

.hueRotationEffect {
  animation: hueRotate 8s linear infinite;
}

@keyframes hueRotate {
  0%,
  100% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(360deg);
  }
}

.play_reveal {
  height: 40px;
  display: inline-flex;
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 0.25s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeOut {
  opacity: 1;
  animation: fadeOut 0.25s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.shiftToCenter {
  transform: translateY(7vh);
}

.stylized--center::before {
  left: calc(50% - 1.5rem / 2) !important;
  bottom: -1.1rem !important;
}

.table {
  display: table;
  margin: 0 auto;
  width: 50%;
}

.flex-col {
  display: flex;
  flex-direction: column;
  place-items: center;
}

@import 'node_modules/react-modal-video/scss/modal-video.scss';


.modal-video-movie-wrap  {
  background: transparent;

 iframe {
  border-radius: 20px !important;
 }
}

.modal-video {
  outline: none;
}